/**
 * Returns a Promise, either resolving with the given value if valid,
 * or rejecting with a Yup ValidationError/generic error
 * @param {object} schema Yup Schema ex: yup.object().shape({ name: yup.string().required() })
 * @param {object} value request body to be validated against Yup schema
 * @param {object} validateOptions Yup's validate options ex: validate(value, { abortEarly: true })
 * @returns {Promise}
 */

export default (
  schema,
  value,
  validateOptions = { abortEarly: true },
) => schema.validate(value, validateOptions)
  .catch((error) => {
    if (error.name === 'ValidationError') { // yup schema validation error
      const validationError = {
        ...error,
        errors: error.inner.length ? error.inner.reduce((errorsObj, currentError) => {
          const errors = errorsObj;
          errors[currentError.path] = currentError.message;
          return errors;
        }, {}) : error.errors,
      };
      throw validationError;
    } else {
      throw Error('We\'re having trouble completing the request.');
    }
  });
